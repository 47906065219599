.vissionfulldiv {
    display: flex;
    justify-content: center;
}

.ourmissiondiv {
    text-align: center;
    font-weight: 800;
    font-size: 18px;
    color: #003396;
    text-transform: uppercase !important;
    background-color: #f1dd23 !important;
}

.vissionrowdiv {
    width: 90%;
    /* margin: auto; */
    box-shadow: 5px 5px 10px 5px #888888;
}


.ourmissionparagraph {
    color: #515151
}

.discovercampassparagraph,
.headingdiscover {
    color: #515151
}

.discoverfulldiv {
    margin-top: 70px;
    display: flex;
    justify-content: center;
    /* width: 70%; */
}

.discovercampass {
    width: 90%;
}

.discoverimg {
    height: 350px;
    width: 400px;
}

@media only screen and (max-width: 991px) {
    .discoverimg {
        height: 380px;
        width: 100%;
    }
}

/* /////////////////////heading and marquee //////////// */
.marqueedivourmission {
    width: 90%;
}

.marqueeheadingourmission {
    text-align: center;
    font-weight: 800;
    font-size: 18px;
    color: #003396;
    text-transform: uppercase !important;
    background-color: #f1dd23 !important;
}

.marqueedivourmissiondiv {

    display: flex;
    justify-content: center;
}

.marqueedivourmission {
    height: 300px;
    box-shadow: 5px 5px 10px 5px #888888;
}

.marqueedivourmissiondiv {
    display: flex;
    justify-content: center;
}

.admissiononlinesystemdiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .admissiontextrowfulldiv {
    display: flex;
    justify-content: center;
} */

.admissiononlinesystem {
    color: #003396;
    text-transform: uppercase !important;
    text-shadow: 3px 3px 3px #888888;
    font-size: 50px;
    font-weight: bolder;

}

@media only screen and (max-width: 666px) {
    .admissiononlinesystem {
        font-size: 40px;
    }
}

@media only screen and (max-width: 666px) {
    .admissiononlinesystem {
        font-size: 25px;
    }
}
.statisticscarddiv{
   
     box-shadow: 5px 5px 20px 5px #888888;
}
.statisticscarddiv:hover{
    background-color: #888888;
}