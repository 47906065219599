.sliderbtnsendrequestdiv {
    /* border: solid yellow; */
    display: flex;
    justify-content: left;
}

.sliderpicmain {
    height: 80vh;
}

.sliderbtnsendrequest {
    font-weight: bold;
    border-color: #00000052 !important;
    border-radius: 20px;
    background-color: #7c817cb6 !important;
    width: 180px;
    height: 60px;
    /* padding: 17px 20px; */

}

.sliderbtnsendrequest:hover {
    color: white;
    background-color: #0b0e0be5 !important;
}

@media only screen and (min-width: 500px) and (max-width: 600px) {
    .sliderbtnsendrequest {
        width: 120px;
        height: 60px;
        font-weight: normal;
    }

    .sliderpicmain {
        height: 60vh;
    }
}

@media only screen and (min-width: 300px) and (max-width: 500px) {
    .sliderbtnsendrequest {

        margin-top: 40px !important;
        width: 90px;
        height: 30px;
        font-weight: normal;
        font-size: 10px;
    }
}

@media only screen and (max-width: 576px) {

    .sliderpicmain {
        height: 40vh;
    }

    .sliderbtnsendrequestdiv {
        display: none;
    }

    .sliderbelowdivslider {
        display: none;
    }

}

@media only screen and (min-width: 576px) and (max-width: 3000px) {
    .sliderbelowdivslider {
        display: none !important;
    }
}

.sliderbelowdivslider {
    background-color: black;
    height: 50px;
}

.sliderbelowdivslider {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sliderbelowdivsliderbtn {

    font-weight: bold;
    border-color: #00000052 !important;
    border-radius: 10px !important;
    background-color: #7c817cb6 !important;
    width: fit-content;
    height: 40px;

}