.pencilicondepttadmin:hover {
    cursor: pointer;
    transition: 0.5s;
    color: blue
}

.deleteicondepttadmin:hover {
    cursor: pointer;
    transition: 0.5s;
    color: red
}



@media only screen and (max-width: 442px) {
    /* .deleteicondepttadmin {

        margin-top: 0px;

    } */
}