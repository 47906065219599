.allaboutusbanerfulldiv{
    margin-top: 3rem;
    background-image: url(../../assets/bg2.jpeg);
    background-position: top;
    height: 300px;    
}
.allaboutbanertext{
    font-weight: 900;
   color: white;
    height: 300px;
    display: flex;
    align-items: center;
    padding-left: 100px;
}
.directorheadingaboutus{
  display: flex;
  justify-content: center;
 
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}
.directorparaaboutus{
    display: flex;
    justify-content: center;
    font-family: "Open Sans", sans-serif;
    color: #444444;

}