.footerfulldiv {
    background-image: url("../../assets/footer-background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;



}

.footerfulldiv {
    color: white;
    display: flex;
    justify-content: center;
}

.footerlink {
    /* cursor: pointer; */
    text-decoration: none;
    color: white;
    height: 40px;
}

.footerlink:hover {

    color: #aabce0;
}

/* @media only screen and (min-width: 900px) {

    .footerlink {
        width: 50%;
    }
} */



.headingfooterlink {
    text-decoration: underline;
}

@media only screen and (max-width: 576px) {

    .footerrowfulldiv {
        text-align: center;
    }
}


.footerrowfulldiv {
    width: 90%;
    /* border: white solid 1px; */
}