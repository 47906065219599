.signupformdiv{
    box-shadow: 5px 5px 10px 5px #888888;
}
.signupformfulldiv{
    margin-top: 100px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
}
.signupformdiv{
    width: 40%;
   text-align: center;
   
}

.inputfieldsignupform{
   width: 100%;
    border: none;
    border-bottom: 1px solid black;
}
.inputfieldsignupform:focus{
   outline: none;
   border-bottom: 2px solid black;
}
.inputtextsignup{
    margin-top: 5px;
    /* margin-left: 20px; */
}

.inputfulldivsignup{
    /* margin-right: 40px; */
    /* border:  red solid ; */
    display: flex;
   justify-content: center;
   
    
   
}
.validationtextsignupApp{
    color: red;
}

@media only screen and (max-width: 700px) {
  .signupformdiv{
    width: 80%;
  }  
}

