/* .navbarposition {
    display: flex;
    justify-content: end;
    border: white solid 1px;
} */
.abcde {
    text-decoration: none;
    color: white;
}

.navbardepttslinkdiv {
    height: 250px;
    overflow-y: scroll;
}