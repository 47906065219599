.textquestionheading {
    display: flex;
    justify-content: center;
}

.anytextmessagequestion {
    font-weight: bold;
}

.getintouchtextheading {
    color: green;
    font-size: medium;
    font-weight: bold;
}

.imagedivquestion {
    background-color: black;
}

.imagetextquestiondiv {
    color: white;
}

.formdivquestion {
    background-color: rgb(247, 247, 247);
}

.imageandtextformfulldivquestion {
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 576px) {
    .imagedivquestion {
        display: none;
    }
}

.questionsubmitbtn {
    font-weight: bold;
    border-color: #00000052 !important;
    border-radius: 20px;
    background-color: #7c817cb6 !important;
    width: 180px;
    height: 60px;
}

.questionsubmitbtn:hover {
    color: white;
    background-color: #0b0e0be5 !important;
}

.wearepresenttextdivquestion {
    display: flex;
    justify-content: center;

}

.wearepresenttextquestion {
    font-weight: bold;

}

.mapfulldivquestion {
    height: 350px;
}