@media only screen and (min-width: 576px) {
    .signupApp3fulldiv {
        width: 50%;
    }

}

@media only screen and (max-width: 576px) {
    .signupApp3fulldiv {
        width: 80%;
    }

}

.signupApp3fulldiv {
    /* border-radius: 20px; */
    box-shadow: 5px 5px 10px 5px #888888;
}