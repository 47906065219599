.middle {
    transition: .5s ease;
    opacity: 0;
    position: relative;
    bottom: 45%;

    /* top: 50%; */
    /* padding-bottom: 100px; */

    left: 80%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    /* display: flex; */
    /* justify-content: center; */
    /* text-align: center; */
    /* align-items: center; */
}

.overlay_container {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
}

.imageourprojectfulldiv:hover .overlay_container {
    opacity: 0.3;
}

.imageourprojectfulldiv:hover .middle {
    opacity: 5;
}

.text_deco {
    background-color: black;
    border-radius: 30px;
    color: white;
    font-size: 16px;
    padding: 16px 32px;
}

.text_deco:hover {
    background-color: #ddd;
    color: black;
}

a {
    text-decoration: none !important;

}