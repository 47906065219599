.Uosnametitle {

    margin-top: 15px;
    /* margin-right: 50px; */

}

.admissionsubtext {
    font-size: 30px;
    padding-left: 60px;
}

.underformtext {
    color: black;
    padding-left:40px
}

.admissionformlogodiv {
    display: flex;
    justify-content: end;
    margin-top: 10px;

}

.admissionformlogo {
    height: 110px;
    width: 110px;
}

.sessionandformstatus {
    width: 160px;
}

.apppicture {
    width: 150px;
    height: 150px;
    border: black solid 1px;
}



@media screen and (max-width: 1080px) {

    .admissionsubtext {
        padding-left: 0px;
    }


}

@media screen and (max-width: 991px) {
    .Uosnametitle {
        text-align: center;
    }
.underformtext {
    padding-left:0px
}
    .admissionformlogodiv {
        display: flex;
        justify-content: center;
    }


}

@media screen and (max-width: 800px) {


    .Uostitle {
        font-size: 40px;
    }

    .admissionsubtext {
        font-size: 20px;
    }

    .sessionandformstatus {
        width: 120px;
    }

}

@media screen and (max-width: 500px) {
    .underformtext {
        font-size: 12px;
    }
}

@media only screen and (min-width: 576px) {
    .admissionformfulldiv {
        width: 70%;
    }

}

@media only screen and (max-width: 576px) {
    .admissionformfulldiv {
        width: 95%;
    }

}

.admissionformfulldiv {
    /* border-radius: 20px; */
    box-shadow: 5px 5px 10px 5px #888888;
}