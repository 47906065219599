
.Textattractiondiv{
    text-align: justify;
    font-size: 15px;
    line-height: 35px;
}
 .textsixedivall{
    font-size: 30px;
}
.btndivallbtn{
    text-align: center;
    font-size: 30px;
    display: inline;
    border: 30px;
    background-color: red;
}
.imageinwifisolution{
    width: 80%;
}