.cardfulldiv {
    display: flex;
    justify-content: center;

}

.carddiv {
    width: 80%;

}

.carddivunder {
    height: 150px;
    border: #003396 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #f1dd23;
    color: #003396;
    /* text-transform: uppercase !important; */
    /* text-shadow: 3px 3px 3px #888888; */
    border-radius: 60px 60px 0px 60px;
}

.carddivunder1 {
    height: 150px;
    border: #003396 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #f1dd23;
    color: #003396;
    /* text-transform: uppercase !important; */
    /* text-shadow: 3px 3px 3px #888888; */
    border-radius: 60px 60px 0px 60px;
}

.cardtext {
    font-weight: bold;
}

.carddivunder:hover {
    cursor: pointer;
    transition: 0.5s;
    box-shadow: 5px 5px 10px 5px #888888;
}

.newbtnadminhome {
    border-radius: 40px 40px 0px 40px;
}

.newbtnadminhome:hover {
    transition: 0.5s;
    box-shadow: 5px 5px 10px 5px #888888;
}

.editdisplayhome {
    border-radius: 0px 0px 50% 50%;
}